<template>
  <div>
    <b-row>
      <b-col
        md="12"
        lg="5"
      >
        <b-card title="Preencha todos os campos para cadastrar seu grupo 🚀">
          <validation-observer ref="simpleRules">
            <b-form @submit.prevent="createGroup">
              <b-row>
                <!-- NOME DO GRUPO -->
                <b-col cols="12">
                  <b-form-group
                    label="Nome do Grupo"
                    label-for="name-grupo"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Nome do Grupo"
                      rules="required"
                    >
                      <b-form-input
                        id="name-group"
                        v-model="nomegrupo"
                        :state="errors.length > 0 ? false:null"
                        placeholder="NOME DO GRUPO"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- TIPO DO GRUPO -->
                <b-col cols="12">
                  <b-form-group
                    label="Tipo do Grupo"
                    label-for="tipogrupo"
                  >
                    <validation-provider
                      name="Tipo do Grupo"
                      rules="required"
                    >
                      <b-form-select
                        id="tipogrupo"
                        v-model="tipogrupo"
                        :options="options"
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- LINK DO GRUPO -->
                <b-col cols="12">
                  <b-form-group
                    label="Link do Grupo"
                    label-for="link-grupo"
                  >
                    <validation-provider
                      name="Link do Grupo"
                      rules="required"
                    >
                      <b-form-input
                        id="link-group"
                        v-model="linkgrupo"
                        placeholder="LINK DO GRUPO"
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <p><strong>🔴Termos de uso do Whatsbot 🔴</strong></p>

                  <h6>⚠️ LEIA COM ATENÇÃO</h6>

                  <p>1- O robô será ativado no seu grupo após o pagamento no valor de R$25,00 mensal.</p>

                  <p>2- O valor de R$25,00 é POR GRUPO ou seja cada ativação de outros grupos,  você terá que pagar novamente e a data de vencimento fica após 30 dias do pagamento.</p>

                  <p>3- Caso apareça um amigo que compre que seja por indicação sua, você ganha +5 dias de bot.</p>

                  <p>4- Se mudou de número, me chame para salvar seu contato.</p>

                  <p>5- A equipe Whatsbot não tem vínculo com as vendas realizadas dentro dos grupos cadastrados e nem nos responsabilizamos pelo que acontece nos mesmos.</p>

                  <p>6- Não garantimos 100% do funcionamento do robô 24 horas por dia, 7 dias por semana pelo seguinte motivo: As regras do whatsapp não permitem robôs em sua plataforma, então pode ser que o número do robô seja banido, logo o robô ficará offline durante o período, nesse caso iremos aguardar o tempo do whatsapp liberar o número para ficar on novamente!</p>

                  <p>7- Não fazemos reembolso do valor pago em nenhuma hipótese.</p>

                  <p>8- Você tem o direito de mudar o robô de um grupo para outro apenas uma vez por mês, sem exceções.</p>

                  <p>9- Você é responsável pela compra do robô, irei dar suporte apenas a você, se outro ADM do seu grupo me chamar para querer saber, de x ou y, ele não será atendido.</p>

                  <p>10- Se desejar fazer críticas faça, desde que seja críticas construtivas, não aceitamos comentários ofensivos, podendo levar ao cancelamento do robô sem reembolso!!</p>

                  <p>11- Não nós responsabilizamos sobre a estabilidade do comando de baixar vídeo.</p>

                  <p>12- O seu robô vence as 21 horas no dia do seu vencimento.</p>

                  <p>13- Ao cadastrar o grupo/comprar você confirma que leu e concordou com os termos de uso.</p>

                  <p>Lembre-se, respeite para ser respeitado!</p>
                </b-col>
                <!-- login button -->
                <b-col cols="12">
                  <b-button
                    variant="primary"
                    type="submit"
                    @click.prevent="createGroup"
                  >
                    Cadastrar
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BFormGroup, BFormInput, BForm, BButton, BRow, BCol, BFormSelect,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import funcs from '@/services/func.service'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const user = JSON.parse(localStorage.getItem('user'))

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      nomegrupo: '',
      tipogrupo: null,
      linkgrupo: '',
      codigogrupo: '',
      options: [
        { value: 'vendas', text: 'VENDAS' },
        { value: 'amizades', text: 'AMIZADES' },
        { value: 'outros', text: 'OUTROS' },
      ],
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          alert('login successfully')
        }
      })
    },

    createGroup() {
      const body = {
        name: this.nomegrupo, type: this.tipogrupo, link: this.linkgrupo, code: this.codigogrupo,
      }
      funcs.creategroup(user.id, body).then(success => {
        console.log(success)
        if (success.data.error === 0) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Sucesso',
              icon: 'CheckIcon',
              text: success.data.message,
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.$router
            .push({ name: 'meus-grupos' })
        } else if (success.data.code.indexOf('ER_DUP_ENTRY')) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro',
              icon: 'XIcon',
              text: 'Já existe cadastro desse grupo',
              variant: 'danger',
            },
          },
          {
            position: 'bottom-right',
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro',
              icon: 'XIcon',
              text: 'Verifique os campos digitados!',
              variant: 'danger',
            },
          },
          {
            position: 'bottom-right',
          })
        }
      })
    },
  },
}
</script>

<style>

</style>
